import React from 'react'
import { graphql } from 'gatsby';
import { Hidden } from 'react-grid-system';
import _get from 'lodash/get';
import _merge from 'lodash/merge';
import Button from 'ui/elements/Button';
import OpticalHeading from 'ui/elements/Heading/OpticalHeading';
import Heading from 'ui/elements/Heading';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { CourseJsonLd, GatsbySeo } from 'gatsby-plugin-next-seo';

import withLocalization from 'decorators/withLocalization';
import ContentBox from 'widgets/ContentBox';
import Testimonials from 'widgets/Testimonials';
import { Section } from 'components/Layout/styles';
import MeetingLayout from 'components/Layout/MeetingLayout';
import { slugify } from 'utils/helpers';

import { Description, Topics, Experience, Faculty, Related, Sidebar } from 'modules/OpenPrograms';
import { palette, setApplicationURL, useSeo, useOpenGraph } from 'modules/OpenPrograms/utils';

const HeroApply = ({
  data,
}) => {
  const { t } = useTranslation('program');

  if (!data) return null;

  const programSlug = slugify(data.title);
  const applicationUrl = setApplicationURL(data, programSlug);

  if (!applicationUrl) {
    return null;
  }

  return (
    <Button
      iconName='arrow-right'
      label={t('applyNow')}
      href={applicationUrl}
      rel="noopener"
      target='_blank'
      variant='outlined'
      color={palette.variant}
    />
  )
}

const Program = ({
  data: rawData,
  location,
}) => {
  const { t, i18n } = useTranslation('program');
  const data = _get(rawData, 'sanityProgram');
  const seo = useSeo(data);
  const openGraph = useOpenGraph(data);
  const heroProps = {
    heading: _get(data, 'subtitle.localized'),
    action: <HeroApply data={data} />,
    color: palette.variant,
  }

  const layoutProps = {
    icon: 'open-programs',
    palette,
    data
  };

  return (
    <React.Fragment>
      <CourseJsonLd {...seo} />
      <GatsbySeo {
        ..._merge({
          openGraph: {
            locale: i18n.language,
            url: location.href,
          }
        }, openGraph)
      } />
      <MeetingLayout
        sidebar={Sidebar}
        heroProps={heroProps}
        {...layoutProps}
      >
        <Section>
          <Hidden xs sm>
            <Heading color={palette.variant} level={1}>{data.title}</Heading>
          </Hidden>
          <ContentBox blocks={data._rawIntro} />
        </Section>

        {
          data.topics && (
            <Section compact>
              <Topics program={data} />
            </Section>
          )
        }

        {
          <Section>
            <OpticalHeading level={6}>{t('level')}</OpticalHeading>
            <Experience program={data} />
          </Section>
        }

        <Section>
          <Heading level={2}>{t('faculty')}</Heading>
          <Faculty program={data} />
        </Section>

        <Section>
          <Description program={data} />
        </Section>

        {
          !!data.testimonials.length && (
            <Testimonials testimonials={data.testimonials} />
          )
        }

        <Section>
          <ContentBox blocks={data._rawBody} />
        </Section>

        {
          !!data.relatedPrograms.length && (
            <Section>
              <OpticalHeading level={4}>{t('relatedPrograms')}</OpticalHeading>
              <Related program={data} />
            </Section>
          )
        }
      </MeetingLayout>
    </React.Fragment>
  )
};

export const query = graphql`
  query ReadProgram($id: String, $language: String!) {
    sanityProgram(id: { eq: $id }) {
      hero {
        ...HeroImage
      }
      contact {
        ...ContactFragment
      }
      meta {
        ...MetaFragment
      }
      testimonials {
        ...TestimonialClean
      }
      _id
      title
      language
      subtitle {
        localized
      }
      faculty {
        ...PersonaFragment
      }
      contact {
        lastName
        linkedin
        phone
        title
        firstName
        email
        bookingPage
      }
      callSchedule
      callScheduleUrl
      onlineApplication
      externalApplication
      applicationForm {
        asset {
          url
        }
      }
      category {
        title
      }
      _rawIntro(resolveReferences: { maxDepth: 10 })
      _rawProgramContent(resolveReferences: { maxDepth: 10 })
      _rawParticipantProfile(resolveReferences: { maxDepth: 10 })
      _rawProgramBenefits(resolveReferences: { maxDepth: 10 })
      _rawProgramFormat(resolveReferences: { maxDepth: 10 })
      _rawBody(resolveReferences: { maxDepth: 10 })
      topics
      leadershipExperience
      workExperience
      fallback_fee
      fallback_location
      relatedPrograms {
        ...RelatedProgram
      }
      cooperationPartnerLogo {
        ...CooperationLogo
      }
      brochure {
        asset {
          url
          originalFilename
        }
      }
      programRuns {
        active
        earlyBirdDate
        feeEarlyBird
        feeRegular
        modules {
          active
          endDate
          location
          startDate
          title
        }
      }
    }

    locales: allLocale(filter: { language: {eq: $language} }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;


export default withLocalization(Program);

